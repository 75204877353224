import {
  HeaderComponent,
  BannerSectionComponent,
  FAQSectionComponent,
  SceneSectionComponent,
  WhatTagLiveLabelSectionComponent,
  EasyToIntroduceSectionComponent,
  FooterComponent,
  OverviewServiceSectionComponent,
  SEOComponent,
  FloatButtonSectionComponent,
  CustomersSectionComponent,
} from '@/components';

const Home = () => (
  <>
    <SEOComponent screenName="top" />
    <main>
      <HeaderComponent />
      <BannerSectionComponent />
      <OverviewServiceSectionComponent />
      <WhatTagLiveLabelSectionComponent />
      <SceneSectionComponent />
      <CustomersSectionComponent />
      <EasyToIntroduceSectionComponent />
      <FAQSectionComponent />
      <FooterComponent withSocial isInTopScreen />
      <FloatButtonSectionComponent />
    </main>
  </>
);
export default Home;
